import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAdjustmentList() {
  // Use toast
  const toast = useToast()

  const refAdjustmentListTable = ref(null)

  const perPage = ref(5)
  const totalAdjustments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const branchFilter = ref(null)
  const reasonFilter = ref(null)
  const typeFilter = ref(null)
  const dateFilterValue = ref(null)

  const current = new Date()
  const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
  const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
  const today = `${current.getFullYear()}-${month}-${date}`
  dateFilterValue.value = today

  const dataMeta = computed(() => {
    const localItemsCount = refAdjustmentListTable.value ? refAdjustmentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAdjustments.value,
    }
  })

  const refetchData = () => {
    refAdjustmentListTable.value.refresh()
  }

  watch([currentPage, perPage, branchFilter, reasonFilter, typeFilter, dateFilterValue], () => {
    refetchData()
  })

  const fetchAdjustments = (ctx, callback) => {
    store
      .dispatch('adjustment/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        branch_id: branchFilter.value,
        reason_id: reasonFilter.value,
        transaction_type: typeFilter.value,
        date_range: dateFilterValue.value,
      })
      .then(response => {
        const { adjustments, total } = response.data.data

        callback(adjustments)
        totalAdjustments.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting inventory adjustment list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchAdjustments,
    perPage,
    currentPage,
    totalAdjustments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAdjustmentListTable,

    refetchData,

    // Filters
    branchFilter,
    reasonFilter,
    typeFilter,
    dateFilterValue,
  }
}
